import React from 'react'
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Toastr = () => {
    return (
        <>
            <ToastContainer/>
        </>
    )
}

export default Toastr