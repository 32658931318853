import { Company, SetCompaniesListInterface } from "./interface";

export class SetCompanies {
    type: string = "SET_COMPANIES_LIST"
    payload: SetCompaniesListInterface = {
        count: null,
        companiesList: null,
    }
    constructor (data: any) {
        let companiesList: Company[] = [];
        this.type = "SET_COMPANIES_LIST"
        if(data) {
            data.data.forEach((item: Company) => {
                companiesList.push(item);
            });
            this.payload.count = data.count
            this.payload.companiesList = companiesList
        }
    }
    action() {
        return {
            type: this.type,
            payload: this.payload
        }
    }
}