import { useDispatch, useSelector } from "react-redux";
import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { updateDashboard } from "../../store/dashboard/action";
import { RootState, StoreInterface } from "../../store/index";
import Layout from "../../shared/components/layout/layout";
import { fetchTask } from "../../store/task/action";
import VehicleTaskCard from '../inspections/components/vehicle-task-card';
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { fetchVehiclesRegistrations } from "../../store/vehicles/action";
import axios from '../../Api';
import { formatDate } from "../../utils";
import { Loader } from "../../store/loader/model";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";

interface DailyStats {
  carsRented: string[];
  totalDaysBooked: string[];
  totalExtendedDaysBooked: string[];
  dailyRevenue: string[];
  vehicleUtilzations: string;
  latePercentage: string;
  totalLate: string;
  totalDaysBookedByAdmin: string[];
}

interface WeeklyStats {
  totalLastWeekRevenue: number;
  totalWeekAgoLastWeekRevenue: number;
  totalWeekBooked:  number;
  totalWeekAgoBooked: number;
  totalLastWeekRentals: number;
  totalWeekAgoLastWeekRentals: number;
  lastOilChangeLastWeek: number,
  lastOilChangeLastWeekAgo: number,
  lastOilChangeLastWeekPercentage: number
}

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pageInfo = useSelector((state: StoreInterface) => state.dashboard);
  const [vehicleWidgetDisplay, setVehicleWidgetDisplay] = useState("count");
  const [dailyStats, setDailyStats] = useState<DailyStats | null>(null);
  const [weeklyStats, setWeeklyStats] = useState<WeeklyStats | null>(null);
  const [yesterday, setYesterday] = useState(null);
  const [lastUpdatedAt, setlastUpdatedAt] = useState(null);
  const [city, setCity] = useState('All');
  const [weeklyFilter, setWeeklyFilter] = useState('All');
  const openTasks = useSelector((state: RootState) => state.taskViewPage.openTask)
  const userData = useSelector((state: RootState) => state.login)
  
  const vehicleRegistrations = useSelector((state: RootState) => state.vehiclesPage.vehiclesRegistrationList)
  const vehicleRegistrationsCount = useSelector((state: RootState) => state.vehiclesPage.count)
  // useEffect(() => {
  //   dispatch(fetchVehiclesRegistrations({
  //     pageNumber: 0,
  //     pageSize: 4
  //   }));
  // }, [userData]);
  useLayoutEffect(() => {
    if(userData.userId) {
      dispatch(fetchTask(true, 4, 0, false, userData.company_id));
      console.log(userData,userData.company_id, "userData")
      dispatch(updateDashboard(userData.company_id));
      dispatch(fetchVehiclesRegistrations({
        pageNumber: 0,
        pageSize: 4
      }, userData.company_id));
      // fetchDailyStats()
    }
  }, [userData])

  useEffect(() => {
    const fetchDailyStats = async () => {
      try {
        dispatch(new Loader(true).action());
        const response = await axios.get(`/api/v1/daily-data?city=${city}`)
        if(response.data) {
          dispatch(new Loader(false).action());
          setDailyStats(response.data?.data)
          setYesterday(response.data.lastUpdatedAt)
        }else{
          dispatch(new Loader(false).action());
        }
      }catch(e) {
        dispatch(new Loader(false).action());
      }
    }
    const fetchWeeklyStats = async () => {
      try {
        dispatch(new Loader(true).action());
        const response = await axios.get(`api/v1/fetch-weekly-data?city=${weeklyFilter}`)
        if(response.data) {
          dispatch(new Loader(false).action());
          setWeeklyStats(response.data?.data)
          setlastUpdatedAt(response?.data?.lastUpdatedAt)
        }else{
          dispatch(new Loader(false).action());
        }
      }catch(e) {
        dispatch(new Loader(false).action());
      }
    }
    if(userData) {
      fetchWeeklyStats()
      fetchDailyStats()
    }
  }, [weeklyFilter, city])

  useEffect(() => {
    const fetchDailyStats = async () => {
      try {
        dispatch(new Loader(true).action());
        const response = await axios.get(`/api/v1/daily-data?city=${city}`)
        if(response.data) {
          dispatch(new Loader(false).action());
          setDailyStats(response.data?.data)
          setYesterday(response.data.lastUpdatedAt)
        }else{
          dispatch(new Loader(false).action());
        }
      }catch(e) {
        dispatch(new Loader(false).action());
      }
    }
    if(userData) {
      fetchDailyStats()
    }
  }, [city]);

  const showDateTime = (vehicleData: any) => {
    let completedCounter = 0
    let startTask;
    let endTask;
    vehicleData.forEach((data: any) => {
        return data.status === "COMPLETED" ? completedCounter ++ : 0   
    })
    if(completedCounter === vehicleData.length) {
        const startDate = JSON.parse(JSON.stringify(vehicleData));
        const closedData = JSON.parse(JSON.stringify(vehicleData));
        startDate.sort((t: any, c: any)=>(new Date(t.createdAt) as any) - (new Date(c.createdAt) as any));
        closedData.sort((t: any, c: any)=>(new Date(t.closedDate) as any) - (new Date(c.closedDate) as any));
        return (
            <>
                {`${moment( startDate[0].createdAt  ).format( "LL" )} ${moment(startDate[0].createdAt).format( "hh:mm A" )} - ${moment( closedData[closedData.length - 1].closedDate  ).format( "LL" )} ${moment(closedData[closedData.length - 1].closedDate).format( "hh:mm A" )}`}
            </>
        )
    } else {
        const tasks = JSON.parse(JSON.stringify(vehicleData));
        tasks.sort((t: any, c: any)=>(new Date(t.createdAt) as any) - (new Date(c.createdAt) as any));
        return (
            <>
                {moment( tasks[0]?.createdAt  ).format( "LL" )} {moment( tasks[0]?.createdAt  ).format( "hh:mm A" )}
            </>
        )
    }
}
  return (
    <Layout>
      {" "}
      <section className="content-body">
        <header className="page-header">
          <div className="page_title">
            <h2> Dashboard</h2>
          </div>
          <div className="page-header-right"></div>
        </header>

        <div className="page_content">
          <div className="row card-widgit">
            <div className="col-lg-7 col-md-6">
              <div className="widget widget-vehicle">
                <h1>{process.env.REACT_APP_ADMIN}</h1>
                <h4>All Vehicles  {pageInfo?.vehiclesWidget?.totalCount ? `(${pageInfo?.vehiclesWidget?.totalCount})` :  ''}</h4>
                <ul className="list-style-none widget-vehicle-list mb-3">
                  {pageInfo?.vehiclesWidget?.vehicles.map((vehicle) => {
                    return (
                      <>
                        <li key={vehicle.status}>
                          <div className="item">
                            <span
                              style={{
                                backgroundColor: vehicle.backgroundColor,
                              }}
                            ></span>{" "}
                            {vehicle.displyName} (
                            {vehicleWidgetDisplay === "count"
                              ? vehicle.count
                              : Math.round(vehicle.percentage) + '%'}
                            )
                          </div>
                        </li>
                      </>
                    );
                  })}
                </ul>
                <div className="control_inline">
                  <label
                    className="control control--radio"
                    onClick={() => setVehicleWidgetDisplay("count")}
                  >
                    Vehicles Count
                    <input
                      type="radio"
                      className="form-check-input"
                      name="vehicleWidgetDisplay"
                      checked={vehicleWidgetDisplay === "count"}
                      onChange={() => setVehicleWidgetDisplay("percentage")}
                    />
                    <div className="control__indicator"></div>
                  </label>
                  <label className="control control--radio">
                    Percentage
                    <input
                      type="radio"
                      className="form-check-input"
                      name="vehicleWidgetDisplay"
                      checked={vehicleWidgetDisplay === "percentage"}
                      onChange={() => setVehicleWidgetDisplay("percentage")}
                    />
                    <div className="control__indicator"></div>
                  </label>
                </div>
              </div>
            </div>
            {
              !userData.company_id && 
              <div className="col-lg-5 col-md-6">
                <div className="widget widget-background">
                  <div className="widget-item">
                    <figure>
                      <img src="/static/images/background-checks.png" alt="" />
                    </figure>
                    <div className="widget-item-detail">
                      <h3>
                        Background <br />
                        Checks
                      </h3>
                      <span className="count">
                        {pageInfo?.backgroundChecksWidget?.count}
                      </span>
                    </div>
                  </div>
                  <div className="widget-arrow">
                    <span>
                      <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                        <title />
                        <g data-name="Layer 2" id="Layer_2">
                          <path d="M22,9a1,1,0,0,0,0,1.42l4.6,4.6H3.06a1,1,0,1,0,0,2H26.58L22,21.59A1,1,0,0,0,22,23a1,1,0,0,0,1.41,0l6.36-6.36a.88.88,0,0,0,0-1.27L23.42,9A1,1,0,0,0,22,9Z" />
                        </g>
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
            }
          </div>
          <div className="row card-widgit stats-row">
          <div className="col-lg-6 col-md-6 stats-row">
              <div className="widget widget-backgroun">
                <div className="widget-item">
                  <div className="col-md-12 stats-row">
                    <div className="row">
                      <div className="col-sm-6 stats-row">
                        <span className="custom-heading pointer" >Weekly Stats</span>
                      </div>
                      <div className="col-sm-6 stats-row">
                        <div className="form-group row" style={{
                          justifyContent: 'space-evenly'
                        }}><label style={{
                          marginTop: "10px"
                        }}>Locations</label>
                          <div className="col-sm-6 stats-row">
                            <select className="form-control" name="city" id="city" onChange={(e) => setWeeklyFilter(e.target.value)}>
                              <option value="All">All</option>
                              <option value="Atlanta">Atlanta</option>
                              <option value="Dallas">Dallas</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    {Object.keys(weeklyStats || {}).map((list, index) => {
                      let data: any = 0
                      let diffRevenue: any = 0
                      let percentageHTML: any = ""
                      let statsTitle = ""
                      if(list === 'totalLastWeekRevenue') {
                        data = weeklyStats?.totalLastWeekRevenue.toFixed(2);
                        diffRevenue = data - (weeklyStats?.totalWeekAgoLastWeekRevenue || 0)
                        statsTitle = 'Last week\'s total rental revenue'
                        data = `$${data}`
                      }
                      if(list === 'totalWeekBooked') {
                        data = weeklyStats?.totalWeekBooked;
                        diffRevenue = data - (weeklyStats?.totalWeekAgoBooked || 0)
                        statsTitle = 'Last week\'s total rental days'
                      }
                      if(list === 'totalLastWeekRentals') {
                        data = weeklyStats?.totalLastWeekRentals;
                        diffRevenue = data - (weeklyStats?.totalWeekAgoLastWeekRentals || 0)
                        statsTitle = 'Last week\'s average daily cars on rent'
                      }
                      if(list === 'lastOilChangeLastWeek') {
                        data = weeklyStats?.lastOilChangeLastWeek;
                        diffRevenue = data - (weeklyStats?.lastOilChangeLastWeekAgo || 0)
                        console.log(diffRevenue, "diffRevenue")
                        console.log(data, "data")
                        console.log(weeklyStats?.lastOilChangeLastWeekAgo, "weeklyStats?.lastOilChangeLastWeekAgo")
                        statsTitle = 'Last week\'s number/% cars with > 30 days since last oil change(as of Sunday)'
                      }
                      console.log(diffRevenue < 0, "diffRevenue < 0")
                      if(!['totalWeekAgoBooked', 'totalWeekAgoLastWeekRevenue', 'totalWeekAgoLastWeekRentals', 'lastOilChangeLastWeekAgo', 'lastOilChangeLastWeekPercentage'].includes(list)) {
                        return (
                          <section className={ `card mb20 task-card dashboard-card` }>
                            <header className="dashboard-card-header 1">
                                <div className="row">
                                  <div className="col-sm-6 stats-row">
                                    <h2 className="card-title weekly-stats-title">
                                      {statsTitle}
                                    </h2>
                                  </div>
                                  <div className="col-sm-3 stats-row">
                                    <small className="small-text stats-value">{list === "lastOilChangeLastWeek" ? `${data} / ${weeklyStats?.lastOilChangeLastWeekPercentage}%` : data}</small>
                                  </div>
                                  <div className={`col-sm-3 stats-row ${
                                    diffRevenue < 0 ? "negative" : "positive"
                                  }`}>
                                    {
                                      diffRevenue < 0 ? 
                                      <span className="percentageChanges">{(Math.abs(diffRevenue) / 100).toFixed(2)}%&nbsp;<FaArrowDown color="red" className="weekly-arrow"/></span>
                                      :
                                      <span className="percentageChanges">{(Math.abs(diffRevenue) / 100).toFixed(2)}%&nbsp;<FaArrowUp color="green" className="weekly-arrow"/></span>}
                                  </div>
                                </div>
                            </header>
                          </section>
                        )
                      }
                    })}
                    <div className="row">
                      <div className="col-md-12">
                        <span className="" >Last Updated {lastUpdatedAt && new Date(lastUpdatedAt).toLocaleString('default', { weekday: 'long' })} | {lastUpdatedAt && formatDate(lastUpdatedAt)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 stats-row">
              <div className="widget widget-backgroun">
                <div className="widget-item">
                  <div className="col-md-12 stats-row">
                    <div className="row">
                      <div className="col-sm-6 stats-row">
                        <span className="custom-heading pointer" >Daily Stats</span>
                      </div>
                      <div className="col-sm-6 stats-row">
                        <div className="form-group row" style={{
                          justifyContent: 'space-evenly'
                        }}><label style={{
                          marginTop: "10px"
                        }}>Locations</label>
                          <div className="col-sm-6 stats-row">
                            <select className="form-control" name="city" id="city" onChange={(e) => setCity(e.target.value)}>
                              <option value="All">All</option>
                              <option value="Atlanta">Atlanta</option>
                              <option value="Dallas">Dallas</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    {Object.keys(dailyStats || {}).map((list, index) => {
                      let data: any = 0
                      let statsTitle = ""
                      if(list === 'dailyRevenue') {
                        data = dailyStats?.dailyRevenue.reduce(function (acc: any, obj: any) { return acc + obj.totalRevenue; }, 0);
                        data = `$${(data.toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                        statsTitle = 'Yesterday\'s total Revenue'
                      }
                      if(list === 'carsRented') {
                        data = dailyStats?.carsRented.reduce(function (acc: any, obj: any) { return acc + obj.vehiclesRented; }, 0);
                        statsTitle = 'Yesterday\'s Cars on Rent'
                      }
                      if(list === 'totalDaysBooked') {
                        data = dailyStats?.totalDaysBooked.reduce(function (acc: any, obj: any) { return acc + obj.days; }, 0);
                        data += dailyStats?.totalExtendedDaysBooked.reduce(function (acc: any, obj: any) { return acc + (obj.durations || 0); }, 0);
                        data += dailyStats?.totalDaysBookedByAdmin.reduce(function (acc: any, obj: any) { return acc + (obj.durations || 0); }, 0);
                        statsTitle = 'Yesterday\'s total days booked'
                      }
                      if(list === 'vehicleUtilzations') {
                        data = `${dailyStats?.vehicleUtilzations}%`;
                        statsTitle = 'Yesterday\'s total utilizations'
                      }
                      if(list === "totalLate") {
                        data = `${dailyStats?.totalLate}`;
                        statsTitle = 'Yesterday\'s late rentals (%/count)'
                      }
                      // if(list === "latePercentage") {
                      //   data = `${dailyStats?.latePercentage}%`;
                      //   statsTitle = 'Yesterday\'s percentage late rentals'
                      // }
                      if(list !== "totalExtendedDaysBooked" && list !== "totalDaysBookedByAdmin") {
                        return (
                          <section className={ `card mb20 task-card dashboard-card` }>
                            <header className="dashboard-card-header 1">
                                <div className="row">
                                  <div className="col-sm-9 stats-row">
                                    <h2 className="card-title">
                                      {statsTitle}
                                    </h2>
                                  </div>
                                  <div className="col-sm-3 stats-row">
                                    <small className="small-text stats-value">{data}</small>
                                  </div>
                                </div>
                            </header>
                          </section>
                        )
                      }
                    })}
                    <div className="row">
                      <div className="col-md-12">
                        <span className="" >Last Updated {yesterday && new Date(yesterday).toLocaleString('default', { weekday: 'long' })} | {yesterday && formatDate(yesterday)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row card-widgit">
            <div className="col-lg-6 col-md-6">
              <div className="widget widget-backgroun">
                <div className="widget-item">
                  <div className="col-md-12">
                    <a className="text-primary dashboard-custom-heading" href="/tasks/open"><span className="custom-heading pointer" >Open Tasks ({openTasks?.count})</span></a>
                    {Object.keys(openTasks?.data || {}).map((list) => {
                      const vehicleData = openTasks?.data[list as any] as any
                      return (
                        <section className={ `card mb20 task-card dashboard-card` }>
                          <header className="dashboard-card-header 1">
                              <h2 className="card-title">{vehicleData?.[0]?.number} - {moment(vehicleData?.[0]?.year).format("YYYY")} {vehicleData?.[0]?.make} {vehicleData?.[0]?.model} ({vehicleData?.length})</h2>
                              <small className="small-text">
                                  {showDateTime(vehicleData)}
                              </small>
                          </header>
                      </section>
                      )
                    })}
                    {openTasks && Object.keys(openTasks?.data || {}).length > 0 && <button onClick={()=>navigate('/tasks/open')} className={'btn dashboard-button'}>View More</button>}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="widget widget-backgroun">
                <div className="widget-item">
                  <div className="col-md-12">
                    <a className="text-primary dashboard-custom-heading" href="#"><span className="custom-heading pointer" >Vehicle Registration Expiration</span></a>
                    {vehicleRegistrations && vehicleRegistrations.map((vehicle) => {
                      return (
                        <section className={ `card mb20 task-card dashboard-card` }>
                          <header className="dashboard-card-header 1">
                              <h2 className="card-title">{vehicle.number} - {moment(vehicle.year).format("YYYY")} {vehicle.make} {vehicle.model}</h2>
                              <small className="small-text">
                                {moment( vehicle.registrationExpDate  ).format( "LL" )}
                              </small>
                          </header>
                      </section>
                      )
                    })}
                    {vehicleRegistrations && vehicleRegistrationsCount > 4 && <button onClick={()=>navigate('/vehicle-registrations')} className={'btn dashboard-button'}>View More</button>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Dashboard;
