import { CompanyPage, SetCompaniesPage } from "./interface";

const initialState = {
    count: 0,
    companiesList: [],
};
export const companiesPage = (
    state: CompanyPage = initialState,
    { type, payload }: SetCompaniesPage
  ) => {
    switch (type) {
      case "SET_COMPANIES_LIST":
        return {
          ...state,
          count: payload.count,
          companiesList: payload.companiesList,
        };

      default:
        return state;
    }
  };
  