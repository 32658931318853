import React from 'react';


const Incidents = () => {

    return (
        <h1>Incident</h1>
    );
}

export default Incidents;