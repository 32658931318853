import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Layout from "../../shared/components/layout/layout";
import DatePicker from 'react-datepicker';
import Api from "../../Api";
import { toast } from "react-toastify";
import { exportData } from "../../utils";
import axios from "../../Api";
import { LastOilChange, PromocodeExportReport, Unrented, Utilization, VehicleSwitchExportHeader } from "../../shared/models/index";
import Select from 'react-select';
import { Loader } from "../../store/loader/model";
import { useDispatch } from "react-redux";
import { PromoCode } from "../../store/promoCode/models";
var moment = require('moment-timezone');

interface Filters {
    startDate: Date | null;
    endDate: Date | null;
    type: string | null;
    company: string[] | null;
    location: string[] | null;
}
const Reports = () => {
    const initialFilters: Filters = {
        startDate: null,
        endDate: null,
        type: null,
        company: null,
        location: null
    }
    const locations = [
        {value: 'All', label: "All Locations"},
        {value: 'Atlanta', label: "Atlanta"},
        {value: 'Dallas', label: "Dallas"}
    ]
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [companies, setCompanies] = useState<any>([])
    const [filters, setFilters] = useState<Filters>(initialFilters)
    const [header, setHeader] = useState<any|null>(VehicleSwitchExportHeader)
    const dispatch = useDispatch()
    useLayoutEffect(()=> {
        const fetchCompanies = async () => {
          try {
            const res = await (await Api.get('/api/v1/companies/list')).data
            if(res && res.data && res.data.length) {
                let companiesArr =  res.data.map((company: any) => ({value: company.id, label: company.name}))
                companiesArr.unshift({value: 'All', label: "All Companies"})
                console.log(companiesArr, "filters.company")
                if(!companiesArr || !companiesArr.length) companiesArr = []  
                setCompanies(companiesArr)
            }
          }catch(e) {
            throw e
          }
        }
        fetchCompanies()
    }, [])
    useEffect(() => {
        if(filters.type) {
            if(filters.type === "switch") {
                setHeader(VehicleSwitchExportHeader)
            }
            if(filters.type === "utilization") {
                setHeader(Utilization)
            }
            if(filters.type === "unrented") {
                setHeader(Unrented)
            }
            if(filters.type === "lastOilChange") {
                setHeader(LastOilChange)
            }
            if(filters.type === "promocode") {
                setHeader(PromocodeExportReport)
            }
        }
    }, [filters.type])
    console.log(filters)
    const handleChange = (
        selectedOption: any,
        name: string
      ) => {
        let options: string[] = []
        console.log(selectedOption, "selectedOption")
        selectedOption.forEach((option: {value: string; label: string}) => {
            return options.push(option.value)
        })
        setFilters({
          ...filters,
          [name]: options
        });
    };
    console.log(filters)
    const filterAction = async () => {
        try {
            dispatch(new Loader(true).action());
            console.log(filters)
            const searchFilter = filters
            if(!filters.type) {
                dispatch(new Loader(false).action());
                return toast.error("Please select report type!")
            }
            if((filters.startDate && !filters.endDate) || (!filters.startDate && filters.endDate)) {
                dispatch(new Loader(false).action());
                return toast.error("Please select start and end date!")
            }
            if (filters.startDate && filters.endDate) {
                if (moment(filters.startDate).isSame(moment(filters.endDate))) {
                    searchFilter.endDate = moment(filters.endDate).add(24, "hours").format("MM-DD-YYYY");
                } else {
                    searchFilter.endDate = moment(filters.endDate).format("MM-DD-YYYY");
                }
                searchFilter.startDate = moment(filters.startDate).format("MM-DD-YYYY");
            }
            if (filters.company?.length) {
                searchFilter.company = filters.company.filter((data) => data !== "All")
            }
            if (filters.location?.length) {
                searchFilter.location = filters.location.filter((data) => data !== "All")
            }
            if(searchFilter.company && !searchFilter.company.length) {
                searchFilter.company = null
            }
            if(searchFilter.location && !searchFilter.location.length) {
                searchFilter.location = null
            }
            if(!['unrented', 'promocode'].includes(filters.type) && !filters.startDate && !filters.endDate) {
                dispatch(new Loader(false).action());
                return toast.error("Please select start date and end date")
            }
            try {
                const response = await axios.post(`/api/v1/report/${filters.type}`, {
                  filters
                })
                exportData(
                  response.data.data,
                  filters.type === "promocode" ? `PromocodeReport-` + moment().format("MMMM-Do-YYYY-HH-mm") + ".xlsx" : `Vehicle${filters.type === "switch" ? "Switch": filters.type === "utilization" ? "Utilization" : filters.type === "lastOilChange" ? "LastOilChange" : "Unrented" }Report-` + moment().format("MMMM-Do-YYYY-HH-mm") + ".xlsx",
                  header,
                  filters.type  
                ); 
                setFilters({
                    ...filters,
                    startDate: new Date(),
                    endDate: new Date(),
                });
                dispatch(new Loader(false).action());
            } catch(err) {
                dispatch(new Loader(false).action());
            }
        }catch(e: any){
            toast.error(e.message)
        }
    }
    const options = (companies: any) => {
        companies =  companies.map((company: any) => ({value: company.id, label: company.name}))
        companies = companies.unshift({value: 'All', label: "All Companies"})
        console.log(companies, "filters.company")
        if(!companies || !companies.length) companies = []
        return companies
    }
    const filterLocation = () => {
        if(filters.location) {
            console.log(filters.location, "filters.location")
            const selectedAll = filters.location.findIndex((data) => data === "All")
            if(selectedAll > -1 && selectedAll === (filters.location.length - 1)) {
                filters.location = ["All"]
            } else if(filters.location && filters.location.length > 1) {
                filters.location = filters.location.filter((data) => data !== "All")
            }
            const filteredValues = filters.location.map((data) => {
                const filterData = locations.find((locationData) => data === locationData.value)
                if(filterData) {
                    return {value: filterData.value, label: filterData.label}
                }
            })
            return filteredValues
        }
        return []
    }
    const filterCompany = () => {
        if(filters.company) {
            const selectedAll = filters.company.findIndex((data) => data === "All")
            if(selectedAll > -1 && selectedAll === (filters.company.length - 1)) {
                filters.company = ["All"]
            } else if(filters.company && filters.company.length > 1) {
                filters.company = filters.company.filter((data) => data !== "All")
            }
            const filteredValues = filters.company.map((data) => {
                const filterData = companies.find((company: any) => data === company.value)
                if(filterData) {
                    return {value: filterData.value, label: filterData.label}
                }
            })
            console.log(filteredValues, "filteredValues")
            return filteredValues
        }
        return []
    }
    console.log(filters.location, "filters.location")
    return (
        <>
            <Layout>
                <section className="content-body">
                    <header className="page-header">
                    <div className="page_title d-flex">
                        <div>
                        <h2>Reports</h2>
                        </div>
                    </div>
                    <div className="page-header-right">
                    </div>
                    </header>

                    <div className="page_content">
                    <div className="white-box">
						<div className="form-group row">
							<label className="col-md-2 col-sm-3 col-xs-12">Choose Report</label>
							<div className="col-md-4 col-sm-6 col-xs-12">
                                <select
                                    className="form-control"
                                    name="type"
                                    onChange={(e) => {
                                        setFilters((prevFilter) => ({...prevFilter, type: e.target.value}));
                                    }}
                                >
                                    <option value={""}>-- Select Report --</option>
                                    <option value={"switch"}>Vehicle Switch Report</option>
                                    <option value={"utilization"}>Utilization Report</option>
                                    <option value={"unrented"}>Unrented Report</option>
                                    <option value={"lastOilChange"}>Last Oil Changed Report</option>
                                    <option value={"promocode"}>Coupon Code Report</option>
                                    
                                </select>
							</div>
						</div>
                        {
                            filters.type && ['switch', 'utilization','unrented', 'lastOilChange'].includes(filters.type) && 
                            <div className="form-group row">
                                        <label className="col-md-2 col-sm-3 col-xs-12">Company Name</label>
										<div className="col-md-4 col-sm-6 col-xs-12">
                                        <Select 
                                            options={companies}
                                            classNamePrefix={"multi-select"}
                                            onChange={(selectedOptions) => handleChange(selectedOptions, 'company')}
                                            isMulti={true}
                                            placeholder="All Companies"
                                            value={filterCompany()}
                                        />
										</div>
                            </div>
                        }
                        {
                            filters.type && ['switch', 'utilization','unrented', 'lastOilChange'].includes(filters.type) && 
                            <div className="form-group row">
                                        <label className="col-md-2 col-sm-3 col-xs-12">Location</label>
										<div className="col-md-4 col-sm-6 col-xs-12">
                                            <Select 
                                                options={locations}
                                                classNamePrefix={"multi-select"}
                                                onChange={(selectedOptions) => handleChange(selectedOptions, 'location')}
                                                isMulti={true}
                                                value={filterLocation()}
                                                placeholder="All Location"
                                            />
                                            {/* <select
                                                className="form-control"
                                                name="location"
                                                onChange={(e) => {
                                                    setFilters((prevFilter) => ({...prevFilter, location: e.target.value}));
                                                }}
                                            >
                                                <option value={""}>-- Select Location --</option>
                                                <option value={"Atlanta"}>Atlanta</option>
                                                <option value={"Dallas"}>Dallas</option>
                                                
                                            </select> */}
                                        </div>
                            </div>
                        }
                        {
                            filters.type && ['switch', 'utilization', 'lastOilChange'].includes(filters.type) && 
							<>
                            <div className="form-group row">
                                <label className="col-md-2 col-sm-3 col-xs-12 date-label">Date Range</label>
								<div className="col-md-4 col-sm-6 col-xs-12">
                                        <div className="calendar-input">
                                        <DatePicker
                                        selected={filters.startDate || null}
                                        className="form-control"
                                        placeholderText="From"
                                        onChange={ ( date: Date ) =>
                                            {
                                                console.log(date, "start")
                                                setFilters((prevFilter) => ({...prevFilter, startDate: date}));
                                        } }
                                        startDate={filters.startDate || new Date()}
                                        showYearDropdown
                                        showMonthDropdown
                                        scrollableYearDropdown
                                        yearDropdownItemNumber={ 100 }
                                        minDate={filters.type === "utilization" ? new Date("2023-10-02") : null}
                                        useShortMonthInDropdown
                                        dateFormat="MM/dd/yyyy"
                                            />
                                        <span className="btn calendar-btn">
                                            <i className="fa fa-calendar" aria-hidden="true"></i>
                                        </span>
                                        </div>
                                </div>
                                <label className="">To</label>
										
                                    <div className="col-md-4 col-sm-6 col-xs-12">
                                        <div className="calendar-input">
                                        <DatePicker
                                            selected={filters.endDate || null}
                                            className="form-control"
                                            placeholderText="To"
                                            onChange={ ( date: Date ) =>
                                                {
                                                    console.log(date, "end")
                                                    setFilters((prevFilter) => ({...prevFilter, endDate: date}));
                                            } }
                                            startDate={filters.endDate}
                                            maxDate={filters.type === "utilization" ? new Date() : null}
                                            minDate={filters.type === "utilization" ? new Date("2023-10-02") : null}
                                            showYearDropdown
                                            showMonthDropdown
                                            scrollableYearDropdown
                                            yearDropdownItemNumber={ 100 }
                                            useShortMonthInDropdown
                                            dateFormat="MM/dd/yyyy"
                                        />
                                        <span className="btn calendar-btn">
                                            <i className="fa fa-calendar" aria-hidden="true"></i>
                                        </span>
                                        </div>
                                        
                                    </div>
								</div>
								{/* <div className="form-group row">
                                        
                                </div> */}
								</>
                        }
                        {/* <div className="row">
                        <div className="col-md-4 col-lg-3">
                            <div className="form-group">
                            <label className="control control--radio control-radio2">
                                Vehicle Switch Report
                                <input
                                type="radio"
                                className="form-check-input"
                                name="type"
                                value="switch"
                                checked={filters?.type === "switch" ? true : false}
                                onChange={(e) => {
                                    setFilters((prevFilter) => ({...prevFilter, type: "switch"}));
                                }}
                                />
                                <div className="control__indicator"></div>
                            </label>
                            </div>
                        </div>
                        </div> */}
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-action text-center mt-2 mb-2">
                                <button
                                    className="btn btn-orange mr-1"
                                    type="submit"
                                    onClick={(e) => filterAction()}
                                >
                                    Download
                                </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </section>
                </Layout>

            {/* delete Modal Start */}
            
            {/* delete Modal End */}

            {/* Error Modal Start */}
            {/* Error Modal End */}

            {/* Success Modal Start */}
        </>
    )
}

export default Reports