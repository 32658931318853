import InspectionFields from "./components/inspection-fields";

const AddInspection = () => {
  return (
    <>
      <InspectionFields />
    </>
  );
};

export default AddInspection;
