import { Dispatch } from "react";
import { Action } from 'redux';
import Api from "../../Api";
import { ResponseError, ResponseReset, ResponseSuccess } from "../shared/model";
import { SetCompanies } from './models';
import { Loader } from '../loader/model';

export const searchCompanies = (
    searchParams: any,
    pageSize: number,
    pageNumber: number
  ) => {
    return async (dispatch: Dispatch<Action>): Promise<void> => {
      dispatch(new Loader(true).action());
      Api.post(`/api/v1/companies/search/${pageSize}/${pageNumber}`, {
        sortType: "DESC",
        sortBy: "updatedAt",
        ...searchParams,
      })
    .then(function (response) {
        dispatch(new SetCompanies(response.data).action());
        dispatch(new Loader(false).action());
    })
    .catch(function (error) {
        console.log(error);
        dispatch(new ResponseError(error.message).action());
    });
    };
  };

  export const fetchCompany = ({id}: {id: number}) => {
    return async (dispatch: Dispatch<Action>): Promise<void> => {
      Api.get(`/api/v1/companies/${id}`)
    .then(function (response) {
        return response.data.data
    })
    .catch(function (error) {
        console.log(error);
        dispatch(new ResponseError(error.message).action());
    });
    };
  };  

  export const deleteCompanyDoc = ({
    id,
    companyId
  }: any) => {
    return async (dispatch: Dispatch<Action>, state: any) => {
      dispatch(new ResponseReset().action());
      Api.delete(`/api/v1/company-documents/delete/${id}`)
        .then(function (response) {
          dispatch(new ResponseSuccess(response.data.message).action());
        })
        .catch(function (error) {
          dispatch(new ResponseError(error.message).action());
        });
    };
  };