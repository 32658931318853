import React from 'react';


const Maintenance = () => {

    return (
        <h1>Maintenance page</h1>
    );
}

export default Maintenance;